import gql from 'graphql-tag';
import { ImageFields, RatingFields, VideoFields } from '../common-fields.queries';
import { SearchVariantFields } from '../search/search-product-drop-fields.queries';
import { ProductAttributeGroupFields } from './attributes/attributes.queries';
import { ProductPricedOptionGroupFields } from './priced-options/priced-options.queries';

/**
 * Product Packaging - Units of Measurement
 */
export const ProductPackagingFields = gql`
	fragment ProductPackagingFields on ProductPrice {
		packaging {
			name
			units
			value
		}
	}
`;

/**
 * Product Unit Price - Units of Measurement
 */
export const ProductUnitPriceFields = gql`
	fragment ProductUnitPriceFields on ProductPrice {
		unitPrice {
			units
			price
			discount {
				type
				previousAmount
				amount
				formattedExpirationDate {
					formatted
				}
			}
		}
	}
`;

/**
 * Product Price Info
 */
const ProductPriceFields = gql`
	fragment ProductPriceFields on ProductVariant {
		priceInfo {
			current
			discount {
				type
				previousAmount
				amount
				formattedExpirationDate {
					formatted
				}
			}
			...ProductPackagingFields
			...ProductUnitPriceFields
		}
	}
	${ProductPackagingFields}
	${ProductUnitPriceFields}
`;

/**
 * Brand Fields
 */
export const ProductBrandFields = gql`
	${ImageFields}
	fragment ProductBrandFields on ProductBrandInfo {
		name
		logo {
			...ImageFields
		}
		url
		isByAppointmentOnly
	}
`;

/**
 * Product Restriction Alternatives
 */
const ProductRestrictionAlternativesFields = gql`
	fragment ProductRestrictionAlternativesFields on ProductSaleRestrictions {
		alternatives {
			variantId
			product {
				id
				brandName
				productId
				title
				variants {
					id
					title
					url
					priceInfo {
						current
					}
					image {
						...ImageFields
					}
				}
			}
		}
	}
	${ImageFields}
`;

/**
 * Product Restrictions
 */
const ProductRestrictionsFields = gql`
	fragment ProductRestrictionsFields on ProductSaleRestrictions {
		restrictions {
			id
			variantId
			description
			isDirectReplacement
		}
	}
`;

/**
 * Product Sale Restrictions
 */
const ProductSaleRestrictionsFields = gql`
	fragment ProductSaleRestrictionsFields on ProductVariant {
		saleRestrictions {
			state {
				abbreviation
				name
			}
			zipCode
			shopAllCompliantProductsUrl
			...ProductRestrictionsFields
			...ProductRestrictionAlternativesFields
		}
	}
	${ProductRestrictionsFields}
	${ProductRestrictionAlternativesFields}
`;

/**
 * Product Replacement Model Fragment
 */
const ProductReplacementModelFields = gql`
	fragment ProductReplacementModelFields on ReplacementModel {
		image {
			...ImageFields
		}
		link {
			name
			url
		}
		price
		description
	}
	${ImageFields}
`;

/**
 * Product Bundle Item
 */
const ProductBundleItemsFields = gql`
	fragment ProductBundleItemsFields on ProductVariant {
		bundleItems {
			title
			modelNumber
			link {
				name
				url
			}
			quantity
			status
			priceInfo {
				current
			}
			image {
				...ImageFields
			}
			isConfigurable
			finishId
			familyId
		}
	}
	${ImageFields}
`;

/**
 * Matching Products Fragment
 */
const MatchingProductFields = gql`
	fragment MatchingProductsFields on SearchProduct {
		id
		familyId
		modelNumber
		title
		url
		isConfigurable
		variants {
			...SearchVariantFields
		}
		priceInfo {
			current
			range {
				min
				max
			}
		}
		squareFootageBased
		rating {
			...RatingFields
		}
		brandName
		image {
			...ImageFields
		}
	}
	${RatingFields}
	${ImageFields}
	${SearchVariantFields}
`;

export const ThreeSixtyImageFields = gql`
	fragment ThreeSixtyImageFields on ThreeSixtyImage {
		id
		description
		thumbnail {
			...ImageFields
		}
	}
	${ImageFields}
`;

/**
 * Product Variant Fields
 */
export const ProductVariantFields = gql`
	fragment ProductVariantFields on ProductVariant {
		id
		title
		modelNumber
		image {
			...ImageFields
		}
		url
		count
		color
		swatchGradient
		hasFreeShipping
		hasAddToCartPricing
		productShowroomCode
		isMadeToOrder
		isQuickShip
		isOutOfStock
		productPricingDisplay
		shippingCutoffMessage
		shippingCutoffExceedsThreshold
		shippingMessage
		shippingLeadTimeMessage
		shippingClassifications
		estimatedDeliveryMessage
		regionalStatus
		sales {
			title
			description
			formattedExpirationDate {
				formatted
			}
		}
		sampleId
		status
		threeSixtyImage {
			...ThreeSixtyImageFields
		}
		...ProductPriceFields
		...ProductSaleRestrictionsFields
		...ProductBundleItemsFields
	}
	${ThreeSixtyImageFields}
	${ProductPriceFields}
	${ProductSaleRestrictionsFields}
	${ProductBundleItemsFields}
	${ImageFields}
`;

/**
 * Fields that are needed for both PLA/PDP.
 * Make when adding to this query make sure it is actually needed on both Pages
 */
export const ProductFamilyFields = gql`
	${VideoFields}
	${ImageFields}
	fragment ProductFamilyFields on ProductFamily {
		id
		application
		url
		title
		productId
		breadcrumbs {
			name
			url
		}
		baseCategory {
			id
			name
		}
		businessCategory {
			id
			name
		}
		media {
			... on Image {
				...ImageFields
			}
			... on Video {
				...VideoFields
			}
		}
		hasFreeInstallation
		hasRecommendedOptions
		isConfigurable
		hasAccessories
		freightCost
		type
	}
`;

export const PRODUCT_PAGE = gql`
	${ProductFamilyFields}
	${ProductVariantFields}
	${ProductAttributeGroupFields}
	${ProductPricedOptionGroupFields}
	${ProductReplacementModelFields}
	${ProductBrandFields}
	${RatingFields}
	${ImageFields}
	query ProductPage($id: Int!, $variantId: Int, $productStatus: [ProductStatusEnum!] = [stock, discontinued], $regionId: String) {
		productFamily(id: $id, variantId: $variantId, regionId: $regionId) {
			... on NotFoundError {
				message
			}
			... on ProductFamily {
				...ProductFamilyFields
				brandInfo {
					...ProductBrandFields
					warrantyText
				}
				attributeGroups {
					...ProductAttributeGroupFields
				}
				pricedOptionGroups {
					...ProductPricedOptionGroupFields
				}
				replacementModels {
					...ProductReplacementModelFields
				}
				collection {
					url
					name
					description
				}
				description
				isDiscontinued
				productId
				resources {
					name
					url
				}
				reviewSummary {
					...RatingFields
				}
				questionsCount
				title
				url
				variants(status: $productStatus) {
					...ProductVariantFields
				}
				hasRecommendedOptions
				hasVariationGroups
				hasReplacementParts
				isConfigurable
				configurationType
				replacementPartsUrl
				relatedCategories {
					id
					name
					url
				}
				recommendedOptions {
					label
					image {
						...ImageFields
					}
				}
			}
		}
	}
`;

export const MATCHING_PRODUCTS = gql`
	${MatchingProductFields}
	query MatchingProducts($familyId: Int!, $paging: PagingInput, $term: String, $collectionTerm: String) {
		matchingProducts(familyId: $familyId, paging: $paging, term: $term, collectionTerm: $collectionTerm) {
			collection {
				...MatchingProductsFields
			}
			accessories {
				...MatchingProductsFields
			}
			relatedProducts {
				...MatchingProductsFields
			}
		}
	}
`;

export const REPLACEMENT_PRODUCT_PARTS = gql`
	${RatingFields}
	${ImageFields}
	query ReplacementParts($familyId: Int!) {
		replacementParts(familyId: $familyId) {
			id
			familyId
			modelNumber
			title
			url
			price
			minPrice
			maxPrice
			squareFootageBased
			rating {
				...RatingFields
			}
			brandName
			image {
				...ImageFields
			}
			diagramLink {
				name
				url
			}
		}
	}
`;

export const COMPARE_PRODUCT_VARIANTS = gql`
	${ImageFields}
	query ProductVariants($variantIds: [Int!]!) {
		productVariants(variantIds: $variantIds) {
			id
			image {
				...ImageFields
			}
			url
			brandName
			title
			modelNumber
		}
	}
`;
